import { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { useDropzone } from 'react-dropzone'


export const UploadModal = ({ setShowModal, showModal, getBalances }) => {


    const [file, setFile] = useState();

    const upload = async () => {
        if (!file) {
            alert("Please select a .json file first")
        }

        const reader = new FileReader();

        reader.addEventListener(
            "load",
            async () => {
                try {
                    var wallets = JSON.parse(reader.result);
                    for (var wallet of wallets) {
                        if (!wallet.address || !wallet.privateKey || !wallet.label) {
                            throw Error("incorrect content");
                        }
                    }
                    sessionStorage.setItem("wallets", reader.result);
                    setShowModal(false);
                    setFile(null);
                    await getBalances();
                } catch (error) {
                    console.log(error)
                    alert(`File is corrupted`);
                    setFile(null);
                }
            },
            false,
        );

        if (file) {
            reader.readAsText(file);
        }
    }

    const onDrop = useCallback(acceptedFiles => {

        if (acceptedFiles.length === 0) {
            alert(`File is not available`)
            return;
        }

        const extensions = acceptedFiles[0].path.split('.').reverse()[0]
        if (extensions !== "json") {
            alert(`Wrong extension. Allowed only .json files`)
            return;
        }

        setFile(acceptedFiles[0])

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function closeModal() {
        setFile(null);
        setShowModal(false);
    }


    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
            style={customStyles}
        >
            <div className="container" style={{ minHeight: "200px", minWidth: "300px" }}>
                <div className="row pt-4">
                    <div {...getRootProps()} >
                        <input {...getInputProps()} />
                        <div className="container text-center" style={{ color: "grey", fontSize: "16px", fontWeight: "bold", minHeight: "120px", maxWidth: "240px", border: "1px solid green", paddingTop: "30px" }}>Drag .json file here or click to select</div>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="container text-center" style={{ fontSize: "14px", fontStyle: "italic" }}>
                        {file?.name}
                    </div>
                </div>
                <div className="row mt-4">
                    <button type="button" className="btn btn-success bold" onClick={upload}>Upload</button>
                </div>
                <div className="row mt-4">
                    <button type="button" className="btn btn-outline-dark bold" onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </ReactModal>
    )
}

