import { useState } from "react";
import ReactModal from "react-modal";

export const ActionModal = ({ setShowModal, showModal, tokenName, tokenAddress, actionType, walletAddress, ethService, callback }) => {
    // tokenName is ETH for BUY action

    const [amount, setAmount] = useState(0);
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoadingState] = useState(false);

    const executeAction = async () => {
        setErrorMsg(null);
        if (!amount) {
            setErrorMsg("Please enter amount");
            return;
        }
        var _amount = parseFloat(amount);
        if (!_amount) {
            setErrorMsg("Incorrect amount");
            return;
        }

        setLoadingState(true);

        const _tokenBalanceBefore = await ethService.getTokenBalance(walletAddress, tokenAddress);
        const _ethBalanceBefore = await ethService.getETHBalance(walletAddress);

        if (actionType === "SELL" && _tokenBalanceBefore < _amount) {
            setErrorMsg(`Not enough tokens. Current balance: ${_tokenBalanceBefore}`);
            setLoadingState(false);
            return;
        }

        if (actionType === "BUY" && _ethBalanceBefore <= _amount) {
            setErrorMsg(`Not enough ETH. Current balance: ${_ethBalanceBefore}`);
            setLoadingState(false);
            return;
        }

        const action = {
            address: walletAddress,
            action: actionType,
            tokenAddress: tokenAddress,
            amount: _amount
        }

        const transactionId = await ethService.processAction(action);
        if (!transactionId) {
            setErrorMsg("Transaction failed.");
            setLoadingState(false);
            return;
        }

        const timestamp = Math.round(Date.now() / 1000);

        const _tokenBalanceAfter = await ethService.getTokenBalance(walletAddress, tokenAddress);
        const _ethBalanceAfter = await ethService.getETHBalance(walletAddress);

        const _tokenDiff = Math.abs(_tokenBalanceAfter - _tokenBalanceBefore);
        const _baseTokenDiff = Math.abs(_ethBalanceAfter - _ethBalanceBefore);

        callback(actionType, _tokenDiff, _baseTokenDiff);

        const url = 'https://api.vs-memes.xyz/addLog';
        const _data = {
            "sk": localStorage.getItem("sk"),
            "wallet": walletAddress,
            "action": actionType,
            "tokenAmount": _tokenDiff,
            "baseTokenAmount": _baseTokenDiff,
            "txId": transactionId,
            "timestamp": timestamp,
            "project": "PONKE"
        };
        fetch(url, {
            body: JSON.stringify(_data),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(response => response.json())
            .then(result => {
                if (result.result === "success") {
                    setLoadingState(false);
                    setShowModal(false);
                } else {
                    setErrorMsg(`Failed to add log. ${result.message}`);
                    setLoadingState(false);
                }
            });

    }

    function closeModal() {
        setErrorMsg(null);
        setLoadingState(false);
        setShowModal(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    async function setMax() {
        let balance;
        if (actionType === "SELL") {
            balance = await ethService.getTokenBalance(walletAddress, tokenAddress);
        } else {
            balance = await ethService.getETHBalance(walletAddress);
        }
        document.getElementById("tokenAmountInput").value = balance;
        setAmount(balance);
    }

    return (

        <ReactModal
            ariaHideApp={false}
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
            style={customStyles}
        >
            <div className="container" style={{ minHeight: "200px", minWidth: "300px" }}>

                <div className="row pt-4">

                    <div className="input-group mb-3">
                        <input placeholder={walletAddress} id="walletInput" type="text" className="form-control" aria-label="Wallet" value={walletAddress} disabled></input>
                    </div>
                    <div className="input-group mb-1">
                        <input id="tokenAmountInput" placeholder={actionType === "BUY" ? "Amount ETH" : `Amount ${tokenName}`} type="text" className="form-control" aria-label="Amount" onChange={({ target }) => setAmount(target.value)}></input>
                        <button type="button" className="btn btn-outline-secondary" onClick={setMax}>Max</button>
                    </div>
                    {
                        errorMsg ? <div className="mt-2" style={{ color: "red" }}>{errorMsg}</div> : <div></div>
                    }
                </div>

                {loading ?
                    <div className="container text-center mt-1 mb-1">
                        <div className="container loading-spinner" style={{ width: "8rem", height: "8rem" }}>
                            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                        <div className="container">Sending TX. Please wait.</div>
                    </div>
                    :
                    <div>
                        {actionType === "BUY"
                            ?
                            <div className="row mt-4">
                                <button type="button" className="btn btn-success bold" onClick={executeAction}>Buy {tokenName}</button>
                            </div>
                            :
                            <div className="row mt-4">
                                <button type="button" className="btn btn-danger bold" onClick={executeAction}>Sell {tokenName}</button>
                            </div>
                        }
                        <div className="row mt-4">
                            <button type="button" className="btn btn-outline-dark bold" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                }


            </div>
        </ReactModal>
    )
}

