function Header() {
  const currentUrl = window.location.pathname;

  const logout = async e => {
    localStorage.removeItem("sk");
    sessionStorage.removeItem("wallets");
    window.location.href = "/login";
  };

  return (

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid navContainer">
        <a className="navbar-brand" href="/"><img className="header-logo img-fluid" src='/assets/logos/logo_black.png' alt=''></img></a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a id='walletsLink' className={currentUrl == '/wallets' ? "nav-link active" : "nav-link"} href="wallets" aria-current={currentUrl == '/wallets' ? "page" : ""}>Wallets</a>
            </li>
            <li className="nav-item">
              <a id='logsLink' className={currentUrl == '/logs' ? "nav-link active" : "nav-link"} href="logs" aria-current={currentUrl == '/logs' ? "page" : ""}>Logs</a>
            </li>
            
          </ul>
          <button className="nav-item" style={{ border: "none", backgroundColor: "transparent", padding: "0px" }} onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
