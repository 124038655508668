import { WalletAction, TransactionResult, Wallet } from '../types/types';

import { ChainId, Token, CurrencyAmount, TradeType, Percent } from '@uniswap/sdk-core'
import { Pair, Route, Trade } from '@uniswap/v2-sdk'
import { Contract, Provider, ethers } from 'ethers';

import { IERC20_ABI } from '../abi/IERC20_ABI';
import { Router02 } from '../abi/Router02';
import { IUniswapV2Pair } from '../abi/IUniswapV2Pair';

import { tokenDecimals } from "../components/projectInfo";


const SWAP_ROUTER_v2 = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
const WETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';

export class EthService {
  private static instance: EthService;

  private readonly provider: Provider;
  private readonly swapGasFee: number;
  private walletMap: Map<string, Contract> = new Map<string, Contract>();

  constructor() {
    this.swapGasFee = 200000;
    const rpc = 'https://mainnet.infura.io/v3/5a419d03f0a0404fb050c27923b60a81';
    this.provider = new ethers.JsonRpcProvider(rpc, ChainId.MAINNET);

    if (EthService.instance) {
      return EthService.instance;
    }
    EthService.instance = this;
  }


  public setWalletsMap(wallets: Wallet[], tokenAddress: string) {
    let _walletMap: Map<string, Contract> = new Map<string, Contract>();
    wallets.forEach(async (wallet: Wallet) => {
      const signer = new ethers.Wallet(wallet.privateKey).connect(this.provider);
      const walletSwapRouter = new ethers.Contract(
        SWAP_ROUTER_v2,
        Router02.abi,
        signer,
      );
      _walletMap.set(wallet.address, walletSwapRouter);
      const allowance = await this.checkAllowance(wallet.address, signer, tokenAddress);
      if (allowance < ethers.parseUnits("1000000", tokenDecimals)) {
        await this.approveToken(signer, tokenAddress);
      }
    });
    this.walletMap = _walletMap;
  }

  public async processAction(
    walletAction: WalletAction,
  ): Promise<any> {
    try {
      const currentGasPrice = (await this.provider.getFeeData()).gasPrice;
      const walletRouter = this.walletMap.get(walletAction.address);
      if (!currentGasPrice || !walletRouter) {
        return false;
      }
      const transactionId = await this.swapTokenUniV2(
        walletAction.tokenAddress,
        walletAction.address,
        currentGasPrice,
        walletRouter,
        walletAction.amount.toString(),
        walletAction.action,
      );
      return transactionId;
    } catch (error) {
      console.error(`ETH processAction() error: ${error}`);
      return false;
    }
  }

  public async getTokenBalance(walletAddress: string, tokenAddress: string) {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, IERC20_ABI, this.provider);
      const balance = (await tokenContract.balanceOf(walletAddress)).toString();
      const balanceParsed = ethers.formatEther(balance);
      return parseFloat(balanceParsed);
    } catch (error) {
      console.error(`ETH Service getTokenBalance() Error: ${error}`);
    }
  }

  public async getETHBalance(walletAddress: string) {
    try {
      const balance = await this.provider.getBalance(walletAddress);
      const balanceInEth = ethers.formatEther(balance);
      return parseFloat(balanceInEth);
    } catch (error) {
      console.error(`ETH Service getETHBalance() Error: ${error}`);
    }
  }


  private async approveToken(signer: ethers.Wallet, tokenAddress: string) {
    const tokenContract = new ethers.Contract(tokenAddress, IERC20_ABI, signer);
    const approveTx = await tokenContract.approve(SWAP_ROUTER_v2, ethers.MaxUint256);
    await approveTx.wait();
  }

  private async checkAllowance(walletAddress: string, signer: ethers.Wallet, tokenAddress: string) {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      IERC20_ABI,
      signer,
    );
    const _allowance = await tokenContract.allowance(walletAddress, SWAP_ROUTER_v2);
    return _allowance;
  }


  private weiToEthValue(wei: bigint): string {
    const weiPerEth = BigInt('1000000000000000000'); // 10^18
    return (wei / weiPerEth).toString();
  }
  private ethToWeiValue(eth: string): bigint {
    const weiPerEth = BigInt('1000000000000000000');
    return BigInt(eth) * weiPerEth;
  }


  private async createPair(tokenFrom: Token, tokenTo: Token): Promise<Pair> {
    const pairAddress = Pair.getAddress(tokenFrom, tokenTo)

    const pairContract = new ethers.Contract(pairAddress, IUniswapV2Pair.abi, this.provider)
    const reserves = await pairContract["getReserves"]()
    const [reserve0, reserve1] = reserves

    const tokens = [tokenFrom, tokenTo]
    const [token0, token1] = tokens[0].sortsBefore(tokens[1]) ? tokens : [tokens[1], tokens[0]]

    const pair = new Pair(CurrencyAmount.fromRawAmount(token0, reserve0.toString()), CurrencyAmount.fromRawAmount(token1, reserve1.toString()))
    return pair
  }

  private async swapTokenUniV2(
    tokenAddress: string,
    walletAddress: string,
    currentGasPrice: bigint,
    walletRouter: Contract,
    tokenAmount: string = '0.0001',
    action: string
  ) {
    try {
      const latestNonce = await this.provider.getTransactionCount(walletAddress, 'latest');

      const token = new Token(ChainId.MAINNET, tokenAddress, tokenDecimals);
      const wethToken = new Token(ChainId.MAINNET, WETH, 18);
      const _tokenDecimals = action === "BUY" ? 18 : tokenDecimals;
      const amount: BigInt = ethers.parseUnits(tokenAmount, _tokenDecimals); // TODO: make sure token units is 18

      const pair = await this.createPair(token, wethToken)

      let path;
      let route;
      let currencyAmount;

      if (action === "BUY") {
        path = [wethToken.address, token.address];
        route = new Route([pair], wethToken, token);
        currencyAmount = CurrencyAmount.fromRawAmount(wethToken, amount.toString());
      } else {
        path = [token.address, wethToken.address];
        route = new Route([pair], token, wethToken);
        currencyAmount = CurrencyAmount.fromRawAmount(token, amount.toString());
      }

      const trade = new Trade(route, currencyAmount, TradeType.EXACT_INPUT)
      const slippageTolerance = new Percent('300', '10000') // 300 bips, or 3.0%
      const amountOutMin = trade.minimumAmountOut(slippageTolerance).toExact() // needs to be converted to e.g. decimal string
      const value = trade.inputAmount.toExact() // // needs to be converted to e.g. decimal string

      let rawTxn;

      if (action === "BUY") {
        rawTxn = await walletRouter.swapExactETHForTokens(
          ethers.parseUnits(amountOutMin, _tokenDecimals),
          path,
          walletAddress,
          Math.floor(Date.now() / 1000) + 60 * 2, // 2 minutes
          {
            gasLimit: this.swapGasFee,
            gasPrice: currentGasPrice * BigInt(3) / BigInt(2),
            nonce: latestNonce,
            value: ethers.parseUnits(value, _tokenDecimals)
          },
        );
      } else {
        rawTxn = await walletRouter.swapExactTokensForETH(
          ethers.parseUnits(value, _tokenDecimals),
          ethers.parseUnits(amountOutMin, _tokenDecimals),
          path,
          walletAddress,
          Math.floor(Date.now() / 1000) + 60 * 2, // 2 minutes
          {
            gasLimit: this.swapGasFee,
            gasPrice: currentGasPrice * BigInt(3) / BigInt(2),
            nonce: latestNonce,
          },
        );
      }


      const awaited = await rawTxn.wait();
      console.log('Swap transaction: ', awaited);
      return awaited;
    } catch (error) {
      console.error(`Error in swapTokenUniV2(): ${error}`);
      return false;
    }
  }

}