import React, { useState, useEffect } from "react";

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [sk, setSk] = useState();
    const [role, setRole] = useState();
    const [errorMsg, setErrorMsg] = useState();

    useEffect(() => {
        const _sk = localStorage.getItem("sk");
        const _role = localStorage.getItem("role");
        if (_sk && _role) {
            setSk(_sk);
            setRole(_role);
            window.location.href = "/wallets";
        }
    }, []);

    const handleSubmit = async e => {
        setErrorMsg(null);
        if (username === "" || password === "") {
            setErrorMsg("Please enter both username and password");
            return;
        }

        const url = 'https://api.vs-memes.xyz/authenticate';
        const _data = { "username": username, "password": password };
        fetch(url, {
            body: JSON.stringify(_data),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(response => response.json())
            .then(result => {
                if (result.result === "success") {
                    setSk(result.sk);
                    setRole(result.role);
                    localStorage.setItem('sk', result.sk);
                    localStorage.setItem('role', result.role);
                    window.location.href = "/wallets";
                } else {
                    setErrorMsg("Incorrect username or password provided");
                }
            });
    }


    return <div>
        <section className="vh-100" style={{ backgroundColor: "#ccc" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{ borderRadius: "0.6rem" }}>
                            <div className="card-body p-5 text-center">

                                <h3 className="mb-4">Welcome to Meme Admin</h3>

                                <div className="form-outline mb-4">
                                    <input placeholder="Username" type="email" id="typeEmailX-2" className="form-control form-control-lg" onChange={({ target }) => setUsername(target.value)} />
                                </div>

                                <div className="form-outline">
                                    <input placeholder="Password" type="password" id="typePasswordX-2" className="form-control form-control-lg" onChange={({ target }) => setPassword(target.value)} />
                                </div>

                                {
                                    errorMsg ? <div className="mt-3" style={{ color: "red" }}>{errorMsg}</div> : <div></div>
                                }

                                <button className="btn btn-primary btn-lg btn-block mt-4" type="submit" onClick={handleSubmit}>Login</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}