import React from "react";
import MaterialTable from "@material-table/core";
import Header from "./Header";
import { ActionModal } from "./ActionModal";
import { UploadModal } from "./UploadModal";
import { EthService } from '../services/eth.service.ts';
import { totalSupply, tokenName, tokenAddress } from "./projectInfo";


export const WalletsPage = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [tokenTotalBalance, setTokenTotalBalance] = useState(0);
    const [ethTotalBalance, setEthTotalBalance] = useState(0);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showSellModal, setShowSellModal] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedWalletIndex, setSelectedWalletIndex] = useState(null);

    const ethService = new EthService();


    const labelLookup = { "Deployer": "Deployer", "Sniping": "Sniping", "Layer 2": "Layer 2" }


    useEffect(async () => {
        if (!localStorage.getItem("sk")) {
            window.location.href = "/login";
        } else {
            await getBalances();
        }
    }, [])


    async function getBalances() {
        setLoadingState(true);
        var sessionWallets = JSON.parse(sessionStorage.getItem("wallets"));
        if (!sessionWallets) {
            setLoadingState(false);
            return
        }
        ethService.setWalletsMap(sessionWallets, tokenAddress);

        var _totalTokens = 0;
        var _totalEths = 0;

        for (var wallet of sessionWallets) {
            const _tokenBalance = await ethService.getTokenBalance(wallet.address, tokenAddress);
            const _ethBalance = await ethService.getETHBalance(wallet.address);

            _totalTokens += _tokenBalance;
            _totalEths += _ethBalance;

            wallet.tokenBalance = _tokenBalance;
            wallet.ethBalance = _ethBalance;

            wallet.supplyPercentage = _tokenBalance / totalSupply * 100;
        }

        setTokenTotalBalance(_totalTokens);
        setEthTotalBalance(_totalEths);

        setWallets(sessionWallets);
        setLoadingState(false);
    }

    function onBuyButtonClick(wallet, walletIndex) {
        setSelectedWallet(wallet);
        setSelectedWalletIndex(walletIndex);
        setShowBuyModal(true);
    }

    function onSellButtonClick(wallet, walletIndex) {
        setSelectedWallet(wallet);
        setSelectedWalletIndex(walletIndex);
        setShowSellModal(true);
    }

    function onUploadButtonClick() {
        setShowUploadModal(true);
    }

    function updateWalletBalance(action, tokenDiff, ethDiff) {
        const dataUpdate = [...wallets];
        const index = selectedWalletIndex;
        const oldWalletData = dataUpdate[index];
        var newData = JSON.parse(JSON.stringify(oldWalletData));
        if (action === "BUY") {
            newData.tokenBalance = newData.tokenBalance + tokenDiff;
            newData.ethBalance = newData.ethBalance - ethDiff;
            setTokenTotalBalance(tokenTotalBalance + tokenDiff);
            setEthTotalBalance(ethTotalBalance - ethDiff);
        } else {
            newData.tokenBalance = newData.tokenBalance - tokenDiff;
            newData.ethBalance = newData.ethBalance + ethDiff;
            setTokenTotalBalance(tokenTotalBalance - tokenDiff);
            setEthTotalBalance(ethTotalBalance + ethDiff);
        }
        newData.supplyPercentage = newData.tokenBalance / totalSupply * 100;
        
        dataUpdate[index] = newData;
        setWallets([...dataUpdate]);

        setSelectedWallet(null);
        setSelectedWalletIndex(null);

    }

    const columns = [
        { title: "Address", field: "address", searchable: true, align: "left", editable: "never", sorting: false, filtering: false },
        { title: "Label", field: "label", searchable: true, align: "left", editable: "never", sorting: false, filtering: true, lookup: labelLookup },
        { title: `${tokenName} Balance`, field: "tokenBalance", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
            return <div>{rowData.tokenBalance.toFixed(1)}</div>
        } },
        { title: `Supply %`, field: "supplyPercentage", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
            return <div>{rowData.supplyPercentage.toFixed(2)}</div>
        } },
        { title: "ETH Balance", field: "ethBalance", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
            return <div>{rowData.ethBalance.toFixed(2)}</div>
        } }
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <div>
                    <div className="container pt-3 pb-3">
                        <div className="container mb-2">
                            <button type="button" className="btn btn-warning" onClick={onUploadButtonClick}>Upload wallets JSON</button>
                        </div>
                        <div className="container">
                            <div>Total {tokenName} balance: <span className="bold">{tokenTotalBalance.toFixed(1)} ({(tokenTotalBalance / totalSupply * 100).toFixed(2)}% of supply)</span></div>
                            <div>Total ETH balance: <span className="bold">{ethTotalBalance.toFixed(2)}</span></div>
                        </div>
                    </div>
                    <MaterialTable
                        columns={columns}
                        data={wallets}
                        title=""
                        actions={[
                            {
                                icon: () => <button type="button" className="btn btn-success btn-sm">Buy</button>,
                                tooltip: `Buy ${tokenName}`,
                                onClick: (event, rowData) => {
                                    onBuyButtonClick(rowData.address, rowData.tableData.index);
                                },
                            },
                            {
                                icon: () => <button type="button" className="btn btn-danger btn-sm">Sell</button>,
                                tooltip: `Sell ${tokenName}`,
                                onClick: (event, rowData) => {
                                    onSellButtonClick(rowData.address, rowData.tableData.index);
                                },
                            },
                        ]}
                        options={{
                            addRowPosition: "first",
                            search: true,
                            columnsButton: false,
                            filtering: true,
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100, 150],
                            headerStyle: {
                                fontWeight: "bold"
                            },
                            filterCellStyle: {
                                paddingTop: "0px",
                                paddingBottom: "8px",
                            }
                        }}
                    />
                </div>
        }
        <UploadModal setShowModal={setShowUploadModal} showModal={showUploadModal} getBalances={getBalances} />
        <ActionModal setShowModal={setShowBuyModal} showModal={showBuyModal} tokenName={tokenName} tokenAddress={tokenAddress} actionType="BUY" walletAddress={selectedWallet} ethService={ethService} callback={updateWalletBalance}/>
        <ActionModal setShowModal={setShowSellModal} showModal={showSellModal} tokenName={tokenName} tokenAddress={tokenAddress} actionType="SELL" walletAddress={selectedWallet} ethService={ethService} callback={updateWalletBalance}/>
    </div>;
} 